import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Home.css";

const Home = () => {
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    arrows: false,
    pauseOnHover: false,
  };
  var settings2 = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    arrows: false,
    pauseOnHover: false,
  };
  var txtsettings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    arrows: false,
  };

  return (
    <>
      <section id="homepage-banner" className="animatediv">
        <div className="col-12 banner_top ">
          <div className="row">
            <h2 className="text-center my-5 home_banner_head">
              Integrating Research to Clinical Dentistry
            </h2>
            <div className="col-5 ">
              <div className="d-flex w-100 h-100 align-items-start justify-content-center">
                {/* <div className="txt-slider">
                  <div className=" p-3">
                    <Slider {...txtsettings}>
                      <p className="txt-slider-content ">
                        Immerse yourself for a remarkable and exclusive
                        opportunity and a truly distinctive experience
                      </p>
                      <p className="txt-slider-content ">
                        3 days of scientific extravaganza
                      </p>
                      <p className="txt-slider-content">
                        Open to Undergraduates, Postgraduates,
                        Faculty/practioner
                      </p>
                    </Slider>
                  </div>
                  <p
                    className="fw-bold p-1"
                    style={{
                      borderBottom: "solid 2px yellow",
                      fontSize: "1.3vw",
                    }}
                  >
                    Stay tuned for further captivating and exhilarating news.
                  </p>
                </div> */}

                <div className="py-2">
                  <img
                    src="./img/home/Capture.JPG"
                    alt=""
                    className="pdf_images"
                  />
                </div>
              </div>
            </div>
            <div className="col-7 h-100 d-flex flex-column justify-content-between align-items-center pt-4 pb-3">
              <div className="slider1 w-100">
                <Slider {...settings}>
                  {Array.from({ length: 4 }).map((_, i) => {
                    return (
                      <div className="" style={{ objectFit: "contain" }}>
                        <img
                          src={`./img/home/${i + 1}.png`}
                          className="img-fluid d-block slider_img center-block mx-auto rounded-3"
                          style={{ objectFit: "cover" }}
                          alt=""
                        />
                      </div>
                    );
                  })}
                </Slider>
              </div>
              <div className="slider2 w-100 ">
                <Slider {...settings2}>
                  {Array.from({ length: 2 }).map((_, i) => {
                    return (
                      <div className="">
                        <img
                          src={`./img/home/${i + 5}.png`}
                          className="img-fluid d-block slider_img2 center-block mx-auto"
                          style={{ objectFit: "cover" }}
                          alt=""
                        />
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
        <div className="banner_bottom">
          <div
            className="d-flex align-items-center justify-content-around"
            style={{ boxShadow: "0px 3px 2px 0px rgba(0, 0, 0, 0.24)" }}
          >
            <p className="fw-bold">
              <span className="text-primary">Date: </span>22nd February and 23rd
              February 2025
            </p>
            <p className="fw-bold">
              <span className="text-primary">Venue: </span>SRM Kattankulathur
              Dental College, SRMIST, Chennai, Tamilnadu, India
            </p>
          </div>
        </div>
      </section>
      <section id="banner-mobile">
        <div className="mobile-slider">
          {/* <Slider
            slidesToShow={1}
            slidesToScroll={1}
            autoplay={true}
            autoplaySpeed={2000}
            speed={2000}
            pauseOnDotsHover={false}
            arrows={false}
          >
            {Array.from({ length: 4 }).map((_, i) => {
              return (
                <div className="" style={{ objectFit: "contain" }}>
                  <img
                    src={`./img/home/${i + 1}.png`}
                    className="img-fluid d-block center-block mx-auto px-1"
                    style={{ objectFit: "cover" }}
                    alt=""
                  />
                </div>
              );
            })}
          </Slider> */}
          <div className="w-100 py-2 px-2" style={{ width: "100%" }}>
            <img src="./img/home/Capture.JPG" className="img-fluid" alt="" />
          </div>
        </div>
        <div className="w-100 mt-3 p-2">
          <div className="p-1">
            <Slider {...txtsettings}>
              <p className="text-white text-center fw-bold fs-3 px-1 ">
                Immerse yourself for a remarkable and exclusive opportunity and
                a truly distinctive experience
              </p>
              <p className="text-white text-center fw-bold fs-3 px-1 ">
                3 days of scientific extravaganza
              </p>
              <p className="text-white text-center fw-bold fs-3 px-1">
                Open to Undergraduates, Postgraduates, Faculty/practioner
              </p>
            </Slider>
          </div>
          <p
            className="fw-bold p-1 text-center"
            style={{
              borderBottom: "solid 2px yellow",
            }}
          >
            Stay tuned for further captivating and exhilarating news.
          </p>
        </div>
        <div
          className="w-100 bg-white py-2 px-1"
          style={{ boxShadow: "0px 3px 2px 0px rgba(0, 0, 0, 0.24)" }}
        >
          <div className="d-flex flex-column py-2 align-items-center justify-content-around">
            <p className="fw-bold">
              <span className="text-primary">Date: </span>22nd February and 23rd
              February 2025
            </p>
            <p className="fw-bold">
              <span className="text-primary">Venue: </span>SRM Kattankulathur
              Dental College,
              <br /> SRMIST, Chennai, Tamilnadu, India
            </p>
          </div>
        </div>
      </section>
    </>
  );
};
export default Home;
